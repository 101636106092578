import React from "react"
import PageGabarit from "./page";
import styled from "styled-components";

const StyledPageGabarit = styled(PageGabarit)`
  a {
    border: none;
    padding: 0;
    text-decoration: underline;

    &:hover {
      color: white;
      background-color: transparent;
    }
  }
`

const PageTexteGabarit = (props) => <StyledPageGabarit {...props}/>

export default PageTexteGabarit
